.content {
  margin-top: 50px;
}

.header-column1-home-container {
  position: relative;
}

.header-column1-home-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -10%;
  width: 120%;
  height: 200px;
  background: linear-gradient(to top, rgba(209, 209, 209, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  z-index: -1;
}


@media (max-width: 767px) {
  .header-column1-home-container::after {
    left: -10%;
    width: 130%;
  }
}

