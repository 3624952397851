@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Comfortaa:wght@300..700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");

.judul {
  font-family: "Merriweather", serif;
}

.nav-link {
  font-family: "Poppins", sans-serif;
}

.header-column1-home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.namaOrang {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
}

.pengikut {
  font-family: "Poppins", sans-serif;
}

.gradient-overlay-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(to bottom, white, transparent);
  z-index: 999;
}

.gradient-overlay-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(to top, white, transparent);
  z-index: 99;
}

.image-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 600px;
}

.image-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.scroll-image {
  width: 30%;
  height: 300px;
  object-fit: cover;
  animation: scroll-up 30s linear infinite;
}

.scroll-down-image {
  width: 30%;
  height: 300px;
  object-fit: cover;
  animation: scroll-down 30s linear infinite;
}

@keyframes scroll-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-600px);
  }
}

@keyframes scroll-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(600px);
  }
}
